import React, { useState } from 'react'
import { classNames } from '@/shared/lib/utils/classNames'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import cls from './phonenumberfield.module.scss'
import { sendMetrik } from '@/shared/lib/utils/sendMetrik'

export const PhoneNumberField = ({ phone, phoneHandler, valid }) => {
    return (
        <div className={cls.PhoneNumberField}>
            <PhoneInput
                onFocus={() => sendMetrik('P_phone_input_click', 'P_phone_input_click')}
                buttonClass={cls.buttonClass}
                dropdownClass={cls.DropdownClass}
                inputClass={classNames(cls.PhoneInput, [], {
                    [cls.invalid]: !valid,
                })}
                value={phone}
                onChange={phoneHandler}
                inputProps={{
                    required: true,
                }}
            />
        </div>
    )
}
