import React, { useEffect, useState } from 'react'
import logo from '@/shared/assets/logo_stellar_bet.webp'
import contact from '@/shared/assets/contact.png'
import { ReactComponent as ContactButton } from '@/shared/assets/endingContactButton.svg'
import { ReactComponent as MobileContButton } from '@/shared/assets/contMobButton.svg'
import { ReactComponent as CloseButton } from '@/shared/assets/closeBtn.svg'
import cls from './contact.module.scss'
import Modal from '@/shared/ui/Modal/Modal'
import axios from 'axios'
import { classNames } from '@/shared/lib/utils/classNames'
import useResize from '@/shared/lib/hooks/useResize'
import { PhoneNumberField } from '@/shared/ui/PhoneNumberField/PhoneNumberField'
import { sendMetrik } from '@/shared/lib/utils/sendMetrik'
export const ContactForm = ({ open, setOpen }) => {
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(true)
    const [nameValid, setNameValid] = useState(true)
    const [phoneValid, setPhoneValid] = useState(true)
    const [valid, setValid] = useState(true)
    const [success, setSuccess] = useState(false)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [status, setStatus] = useState(false)
    const windowWidth = useResize()

    const phoneHandler = (value) => {
        setPhone(value)
        setPhoneValid(validatePhone(value))
    }
    const validatePhone = (phone) => {
        const regex = /^\d{4,17}$/
        return regex.test(phone)
    }
    const isValidEmail = (value) => {
        let regEmail =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!regEmail.test(value.target.value)) {
            setEmailValid(false)
        } else {
            setEmailValid(true)
        }

        setEmail(value.target.value)
    }
    const nameHandler = (value) => {
        setName(value.target.value)
        if (value.target.value.trim() === '') {
            setNameValid(false)
        } else {
            setNameValid(true)
        }
    }

    const onSubmit = () => {
        let address = 'https://stellar-softs.com/api/stellarbets/contact'
        if (emailValid && nameValid && phoneValid && email?.trim() && name?.trim() && phone?.trim()) {
            setValid(true)
            setStatus(true)
            sendMetrik('old_submit_form_success', 'old_submit_form_success')
            const response = axios
                .post(address, {
                    email: email,
                    name: name,
                    phone: String(phone),
                })
                .then((response) => {
                    console.log(response)
                    setSuccess(true)
                })
                .catch((error) => {
                    setSuccess(false)
                })
        } else {
            setValid(false)
            sendMetrik('old_submit_form_fail', 'old_submit_form_fail')
        }
    }
    useEffect(() => {
        if (open === false) {
            setStatus(false)
        }
    })
    const render = () => {
        if (status === false) {
            return (
                <>
                    <CloseButton
                        className={cls.CloseButton}
                        onClick={() => setOpen(false)}
                    />
                    <svg
                        className={cls.glowing}
                        width="508"
                        height="438"
                        viewBox="0 0 508 438"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g filter="url(#filter0_f_555_58526)">
                            <path
                                d="M392.83 212.105C392.83 254.952 424.157 302.942 392.83 324.535C370.611 339.85 293.489 335.385 255.626 335.385C212.257 335.385 209.451 307.486 179.975 285.547C147.436 261.329 101.728 213.357 101.728 174.931C101.728 101.695 146.184 101.942 237.43 101.942C328.676 101.942 392.83 138.869 392.83 212.105Z"
                                fill="url(#paint0_linear_555_58526)"
                            />
                        </g>
                        <defs>
                            <filter
                                id="filter0_f_555_58526"
                                x="0.525246"
                                y="0.738747"
                                width="507.431"
                                height="436.455"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="BackgroundImageFix"
                                    result="shape"
                                />
                                <feGaussianBlur
                                    stdDeviation="50.6015"
                                    result="effect1_foregroundBlur_555_58526"
                                />
                            </filter>
                            <linearGradient
                                id="paint0_linear_555_58526"
                                x1="137.805"
                                y1="63.489"
                                x2="297.707"
                                y2="304.868"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#15FF00" />
                                <stop
                                    offset="0.605"
                                    stop-color="#00FF88"
                                />
                                <stop
                                    offset="1"
                                    stop-color="#00E6FF"
                                />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div className={cls.topBlock}>
                        <h1>Become a partner</h1>
                        <div className={cls.logoContainer}>
                            <h1>of</h1>
                            <img
                                src={logo}
                                alt="Stellar Bets Logo"
                            />
                        </div>
                    </div>

                    <div className={cls.bottomBlock}>
                        <div className={cls.fields}>
                            <p>Connect Stellar Bets today</p>
                            <input
                                className={classNames(cls.nameField, [], {
                                    [cls.invalid]: !nameValid,
                                })}
                                onFocus={() => sendMetrik('old_name_input_click', 'old_name_input_click')}
                                type="text"
                                placeholder="Name*"
                                value={name}
                                onChange={nameHandler}
                            />
                            <input
                                className={classNames(cls.emailField, [], {
                                    [cls.invalid]: !emailValid,
                                })}
                                onFocus={() => sendMetrik('old_email_input_click', 'old_email_input_click')}
                                type="email"
                                placeholder="Email*"
                                value={email}
                                onChange={isValidEmail}
                            />
                            <PhoneNumberField phone={phone} valid={phoneValid} phoneHandler={phoneHandler} />
                            {windowWidth > 820 ? (
                                <ContactButton
                                    onClick={() => {
                                        onSubmit()
                                        sendMetrik('old_submit_form', 'old_submit_form')
                                    }}
                                    className={cls.contactButton}
                                />
                            ) : (
                                <MobileContButton
                                    onClick={() => {
                                        onSubmit()
                                        sendMetrik('old_submit_form', 'old_submit_form')
                                    }}
                                    className={cls.contactButton}
                                />
                            )}
                            {!valid && <p className={cls.alert}>incorrect filling *</p>}
                        </div>
                        <img
                            alt="contact"
                            src={contact}
                        />
                    </div>
                </>
            )
        } else {
            return (
                <div className={cls.successMessage}>
                    <h1>Your request has been accepted</h1>
                    <div className={cls.container}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="114"
                            height="115"
                            viewBox="0 0 114 115"
                            fill="none"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M104.5 57.5C104.5 83.7333 83.2333 105 57 105C30.7665 105 9.5 83.7333 9.5 57.5C9.5 31.2665 30.7665 10 57 10C83.2333 10 104.5 31.2665 104.5 57.5ZM76.1439 43.1059C77.5352 44.4972 77.5352 46.7528 76.1439 48.1439L52.3939 71.8939C51.0026 73.2852 48.7473 73.2852 47.3559 71.8939L37.8559 62.3939C36.4647 61.0026 36.4647 58.7473 37.8559 57.3561C39.2472 55.9648 41.5028 55.9648 42.8941 57.3561L49.875 64.3367L60.4903 53.7214L71.1061 43.1059C72.4973 41.7147 74.7526 41.7147 76.1439 43.1059Z"
                                fill="#48D931"
                            />
                        </svg>
                        <div className={cls.text}>
                            <p>Your application has been accepted. Our manager will contact you as soon as possible</p>
                            <p>Opening hours: Mon-Fri, 10 am - 20pm</p>
                        </div>
                    </div>
                    <button
                        onClick={() => setOpen(false)}
                        className={cls.closeSucButton}
                    >
                        Close
                    </button>
                </div>
            )
        }
    }
    return (
        <Modal
            isOpen={open}
            setOpen={setOpen}
        >
            <div className={cls.ContactForm}>
                {render()}
                <svg
                    className={cls.largeGlow}
                    width="1672"
                    height="1070"
                    viewBox="0 0 1672 1070"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_f_99_21)">
                        <path
                            d="M1411.51 534.831C1411.51 614.519 1440.99 726.965 1333.05 778.146C1230.56 826.744 994.657 816.121 841.268 816.121C704.216 816.121 423.561 833.714 325.22 793.969C197.581 742.383 271.027 622.577 271.027 534.831C271.027 455.143 217.279 334.61 325.22 283.428C427.711 234.831 687.879 253.541 841.268 253.541C994.657 253.541 1248.76 234.831 1351.25 283.428C1459.19 334.61 1411.51 455.143 1411.51 534.831Z"
                            fill="url(#paint0_linear_99_21)"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_f_99_21"
                            x="0"
                            y="0"
                            width="1672"
                            height="1070"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                        >
                            <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                            />
                            <feGaussianBlur
                                stdDeviation="125"
                                result="effect1_foregroundBlur_99_21"
                            />
                        </filter>
                        <linearGradient
                            id="paint0_linear_99_21"
                            x1="458.841"
                            y1="286.229"
                            x2="1209.64"
                            y2="916.177"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#D505FF" />
                            <stop
                                offset="1"
                                stop-color="#724AFF"
                            />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </Modal>
    )
}
