import React from "react"
import logo from "@/shared/assets/logo_stellar_bet.webp"

import cls from "./footer.module.scss"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { useLocation, useNavigate } from "react-router-dom"
import {sendMetrik} from "@/shared/lib/utils/sendMetrik";
import {scrollIntoView} from "@/shared/lib/utils/scrollIntoView";
export const Footer = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const navigationItem = (content, href, offset) => {
        if (location.pathname !== "/") {
            return (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/")}>
                    {content}
                </span>
            )
        } else {
            return (
                <AnchorLink
                    href={href}
                    offset={() => offset}>
                    {content}
                </AnchorLink>
            )
        }
    }
    return (
        <div className={cls.footerWrapper}>
            <footer className={cls.Footer}>
                <div className={cls.topblock}>
                    <img alt="logo"
                         src={logo}
                         className={cls.logo}
                         onClick={() => {
                             if (location.pathname !== "/") {
                                 navigate("/")
                             }
                             scrollIntoView('home')
                         }}
                    />

                    <nav>
                        <p
                            onClick={() =>
                                navigate("/distributor")
                            }>
                            Become a distributor
                        </p>
                        <p onClick={() => {
                            if (location.pathname !== "/") {
                                navigate("/")
                            }
                            scrollIntoView('home')
                        }}>Home</p>
                        <p onClick={() => {
                            if (location.pathname !== "/") {
                                navigate("/")
                            }
                            scrollIntoView('features')
                        }}>Features</p>
                        <p onClick={() => {
                            if (location.pathname !== "/") {
                                navigate("/")
                            }
                            scrollIntoView('contacts')
                        }}>Contacts</p>
                    </nav>
                </div>
                <div className={cls.copyright}>
                    <p>© 2024 Stellar Bets</p>
                    <p>All rights reserved.</p>
                </div>
            </footer>
        </div>
    )
}
